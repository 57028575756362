import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  FormQuestionWrapper,
  FormQuestionHeading,
  FormQuestionSubheading,
  Optional,
} from './'

export const StyledTextArea = styled.textarea`
  padding: 8px;
  background: none;
  border-color: rgb(238, 238, 238);
  height: 120px;
  width: 100%;
  line-height: 24px;
  font-size: 16px;
  flex-grow: 1;
  flex-shrink: 1;
  transition: border-color 0.6s ease-in-out;
  resize: none;
  &::placeholder {
    color: #bbb;
  }
  &:focus {
    border-color: cornflowerblue;
    outline: none;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
`

export const TextAreaInput = (props) => (
  <FormQuestionWrapper>
    <FormQuestionHeading>
      {props.title}
      {!props.required && <Optional/>}
    </FormQuestionHeading>
    {props.subheading && <FormQuestionSubheading>
      {props.subheading}
    </FormQuestionSubheading>}
    <StyledTextArea
      {...props}
      onChange={props.controlFunc}
    />
  </FormQuestionWrapper>
)

TextAreaInput.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  subheading: PropTypes.string,
  content: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}
