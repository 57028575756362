import styled from 'styled-components'

export const BodyContainer = styled.div`
position: absolute;
left: 0;
right: 0;
top: 83px;
width: 100%;
height: calc(100% - 83px);
background-color: ${(props) => props.theme.mainBackground};

overflow: auto;
`
