// export const seerColours = {
//   darkPink: '#C62547',
//   pink: '#ef465c',
//   white: '#ffffff',
//   grey: '#bfbfbf',
//   black: '#353535',
//   green: '#43b39e',
// }

export const colours = {
  mainDark: '#C62547',
  main: '#ef465c',
  mainBackground: '#ffffff',
  offsetBackground: '#bfbfbf',
  bodyText: '#353535',
  accent: '#43b39e',
}
