import React from 'react'
import PropTypes from 'prop-types'
import {
  FormWrapper,
  Heading,
  FormQuestionWrapper,
  ClickLink,
} from '../generic'

export const BookingEmailSuccessPostFail = ({ handleClick }) => {
  return (
    <FormWrapper>
      <Heading>
        Email added successfully, booking details update failed
      </Heading>
      <FormQuestionWrapper>
        Form submission failed. The clinician&apos;s details have been added to the list, however there is usually some delay for the email to be usable,
        so chances are if you try again in 10 minutes, things will be ready to go.
        <br /><br />
        If you have not already, please <ClickLink onClick={handleClick}>try again</ClickLink>.
        <br /><br />
        If the problem persists, please <ClickLink href='mailto:braden@seermedical.com'>send us an email</ClickLink> or call us on 1300&nbsp;869&nbsp;888.
      </FormQuestionWrapper>
    </FormWrapper>
  )
}

BookingEmailSuccessPostFail.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
