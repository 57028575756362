import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  FormQuestionWrapper,
  FormQuestionHeading,
  FormQuestionSubheading,
  Optional,
} from './'

// Single input styles
export const StyledSingleInput = styled.input`
  background: none;
  border-color: rgb(238, 238, 238);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  height: 24px;
  width: ${props => (props.short ? 'auto' : '300px')};
  line-height: 24px;
  font-size: 16px;
  flex-grow: 1;
  flex-shrink: 1;
  transition: border-color 0.6s ease-in-out;
  &::placeholder {
    color: #bbb
  }
  &:focus {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-color: cornflowerblue;
    outline: none;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
`

export class SingleInput extends Component {
  static propTypes = {
    inputType: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'password']).isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    controlFunc: PropTypes.func.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    autoComplete: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    regex: PropTypes.instanceOf(RegExp),
    short: PropTypes.bool,
    subheading: PropTypes.string, // Added propType for subheading
  }

  constructor(props) {
    super(props)
    this.state = {
      isValid: true,
    }
  }

  isValid() {
    const regex = this.props.regex || /./
    return !this.props.content || this.props.content.search(regex) !== -1
  }

  doValidation() {
    const isValid = this.isValid()
    this.setState({ isValid })
  }

  render() {
    return (
      <FormQuestionWrapper>
        <FormQuestionHeading>
          {this.props.title}
          {!this.props.required && <Optional/>}
        </FormQuestionHeading>
        {this.props.subheading && <FormQuestionSubheading>
          {this.props.subheading}
        </FormQuestionSubheading>}
        <StyledSingleInput
          {...this.props}
          name={this.props.name}
          autoComplete={this.props.autoComplete || 'off'}
          required={this.props.required}
          type={this.props.inputType}
          value={this.props.content}
          onChange={this.props.controlFunc}
          placeholder={this.props.placeholder}
          onBlur={() => (this.props.regex) && this.doValidation()}
        />
        {/* <Validation display={(!this.state.isValid).toString()}>
          {this.props.validationMessage || 'Invalid input'}
        </Validation> */}
      </FormQuestionWrapper>
    )
  }
}
