import styled from 'styled-components'

// Single input styles
export const FormQuestionSubheading = styled.div`
margin-top: 0px;
margin-bottom: 12px;
font-size: 16px;
font-weight: 100;
color: grey;
`
