import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledSingleInput } from './'

const OtherInput = styled(StyledSingleInput)`
  display: inline;
  position: relative;
  top: 3px;
  left: 4px;
`

export const StyledCheckboxOrRadioInput = styled.input`
  border-radius: 10%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: ${props => props.small ? '28px' : '30px'};
  width: ${props => props.small ? '28px' : '30px'};
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: ${(props) => props.theme.mainBackground};
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;

  &:hover {
    background: #9faab7;
  }

  &:checked {
    background: ${(props) => props.requiredSelection ? props.theme.offsetBackground : props.theme.mainDark};
    ${(props) => props.requiredSelection ? 'cursor: not-allowed;' : ''}
  }

  &:checked::before {
    height: ${props => props.small ? '28px' : '30px'};
    width: ${props => props.small ? '28px' : '30px'};
    position: absolute;
    content: '✔';
    display: inline-block;
    font-size: ${props => props.small ? '18px' : '20px'};
    text-align: center;
    line-height: ${props => props.small ? '28px' : '30px'};
  }

  border-radius: ${props => (props.type === 'radio' ? '50%' : '10%')};

  &::after {
    border-radius: ${props => (props.type === 'radio' ? '50%' : '10%')};
  }
`

export const Label = styled.label`
  cursor: pointer;
  position: relative;
  left: 2px;
  top: 3.33px;
  font-size: ${props => props.small ? '0.9em' : '1em'};
`

export const Group = styled.div`
  padding-bottom: 6px;
`

const TcsSpan = styled.span`
  user-select: none;
  cursor: default;
`

const TcsClickable = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: #222b70;
`

export const CheckboxOrRadioGroupNoHeading = (props) => {
  return (
    <div>
      {props.options.map((opt, i) => {
        const requiredSelection = Boolean(props.requiredSelections && props.requiredSelections.includes(opt))
        if (opt === 'Other:') {
          // Add extra input textbox for 'Other'
          return <CheckboxOrRadioGroupOther key={opt} {...props}/>
        } else {
          return (
            <Group key={opt}>
              <StyledCheckboxOrRadioInput
                name={props.name} // name of group
                onChange={!requiredSelection ? props.controlFunc : undefined}
                readOnly={requiredSelection}
                value={opt}
                key={opt + '_' + props.setName + '_input'}
                id={opt + props.setName}
                checked={props.selectedOptions.includes(opt) || requiredSelection }
                type={props.type}
                requiredSelection={requiredSelection}
              />
              {props.noClickLabel ?
                <TcsSpan>
                  I have read and agree to the <TcsClickable onClick={props.onTcsClick}>terms and conditions</TcsClickable>.
                </TcsSpan>
                :
                <Label
                  htmlFor={opt + props.setName}
                  key={opt + '_' + props.setName + '_label'}
                  small={props.small}
                >
                  {props.labels ? props.labels[i] : opt}
                </Label>
              }
            </Group>
          )
        }
      })}
    </div>
  )
}

CheckboxOrRadioGroupNoHeading.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func,
  requiredSelections: PropTypes.arrayOf(PropTypes.string),
  setName: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  noClickLabel: PropTypes.bool,
  onTcsClick: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.string),
  small: PropTypes.bool,
}

const CheckboxOrRadioGroupOther = (props) => {
  // Add extra input textbox for 'Other'
  const opt = 'Other:'
  return (
    <Group key={opt}>
      <StyledCheckboxOrRadioInput
        name={props.name} // name of group
        onChange={props.controlFunc}
        value={opt}
        key={opt + '_' + props.setName + '_input'}
        id={opt + props.setName}
        checked={props.selectedOptions.indexOf(opt) > -1}
        type={props.type}
      />
      <Label
        htmlFor={opt + props.setName}
        key={opt + '_' + props.setName + '_label'}
      >
        {opt}
      </Label>
      <OtherInput
        short
        autoComplete={'off'}
        name={props.name}
        value={props.otherText}
        type='text'
        onChange={props.setOther}
      />
    </Group>
  )
}

CheckboxOrRadioGroupOther.propTypes = {
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  otherText: PropTypes.string,
  setOther: PropTypes.func.isRequired,
}
