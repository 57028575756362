import styled from 'styled-components'

// Single input styles
export const SectionHeading = styled.h3`
  padding: 10px 20px;
  font-size: 24px;
  margin-left: -20px;
  padding-left: 36px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 100;

  width: calc(100% + 40px);
  display: flex;
  color: ${(props) => props.theme.mainBackground};
  background-color: ${(props) => props.theme.main};
`

export const SubsectionHeading = styled(SectionHeading)`
  padding: 16px;
  font-size: 24px;
  background-color: #456aab;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 80%;
`
