import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledCheckboxOrRadioInput = styled.input`
  border-radius: 10%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: ${props => props.small ? '28px' : '30px'};
  width: ${props => props.small ? '28px' : '30px'};
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;

  &:hover {
    background: #9faab7;
  }

  &:checked {
    background: ${(props) => props.theme.mainDark};
  }

  &:checked::before {
    height: ${props => props.small ? '28px' : '30px'};
    width: ${props => props.small ? '28px' : '30px'};
    position: absolute;
    content: '✔';
    display: inline-block;
    font-size: ${props => props.small ? '18px' : '20px'};
    text-align: center;
    line-height: ${props => props.small ? '28px' : '30px'};
  }

  border-radius: ${props => (props.type === 'radio' ? '50%' : '10%')};

  &::after {
    border-radius: ${props => (props.type === 'radio' ? '50%' : '10%')};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #eeeeee;
    color: #aaaaaa;
  }
`

export const Label = styled.label`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  position: relative;
  left: 2px;
  top: 3.33px;
  font-size: ${props => props.small ? '0.9em' : '1em'};
  color: ${props => props.disabled ? '#9d9d9d' : 'inherit'};
`

export const Group = styled.div`
  padding-top: 12px;
  padding-bottom: 6px;
  text-align: center;
`

export const SingleCheckbox = (props) => (
  <Group>
    <StyledCheckboxOrRadioInput
      small={props.small}
      name={props.name}
      onChange={props.controlFunc}
      checked={props.checked}
      id={props.name}
      type='checkbox'
      disabled={props.disabled}
    />
    <Label
      disabled={props.disabled}
      htmlFor={props.name}
      small={props.small}
    >
      {props.text}
    </Label>
  </Group>
)

SingleCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  small: PropTypes.bool,
}
