import styled from 'styled-components'

export const ClickLink = styled.a`
text-decoration: none;
font-weight: 600;
color: rgb(39, 47, 209);
cursor: pointer;

  &:visited {
    color: #0c20b7;
  }
`

// export default ClickLink
