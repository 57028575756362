import styled from 'styled-components'

// Form wrapper
export const FormWrapper = styled.div`
background-color: rgba(255, 255, 255, 1);
width: 640px;
padding: 20px;
margin: 40px auto;
box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
@media (max-width: 660px) {
  width: 90%;
}
`

// export default FormWrapper
