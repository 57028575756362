import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const BannerLinkContainer = styled.div`
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  width: 140px;
  height: 60px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  color: #454545;
  background-color: transparent;
  transition: 0.1s background-color linear, 0.1s color linear, 0.1s border-color linear;

  &:hover, &:disabled {
    text-decoration: none;
    color: ${(props) => props.theme.main};
  }

  &:disabled {
    cursor: wait;
  }

  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderContainer = styled.header`
  width: 100%;
  height: 83px;
  background: #e0e0e0;
  color: ${(props) => props.theme.mainBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  position: fixed;
  top: 0px;
  box-sizing: border-box;
  border-bottom: 2px solid #ccc;
  z-index: 20;
`

export const HeaderInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px;
`

export const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`

export const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

// COMPONENTS
export const Header = (props) => {
  return (
    <HeaderContainer style={{ ...props.style }}>
      <HeaderInnerContainer>
        {props.children}
      </HeaderInnerContainer>
    </HeaderContainer>
  )
}

Header.propTypes = {
  style: PropTypes.object, // Optional prop
  children: PropTypes.node.isRequired,
}

export const HeaderLeft = (props) => {
  return (
    <HeaderLeftContainer>
      {props.children}
    </HeaderLeftContainer>
  )
}

HeaderLeft.propTypes = {
  children: PropTypes.node.isRequired,
}

export const HeaderRight = (props) => {
  return (
    <HeaderRightContainer>
      {props.children}
    </HeaderRightContainer>
  )
}

HeaderRight.propTypes = {
  children: PropTypes.node.isRequired,
}

export const BannerLink = (props) => {
  return (
    <BannerLinkContainer {...props}>
      {props.children}
    </BannerLinkContainer>
  )
}

BannerLink.propTypes = {
  children: PropTypes.node.isRequired,
}
