import styled from 'styled-components'

// Single input styles
export const FormQuestionHeading = styled.div`
font-size: 20px;
font-weight: bold;
padding-bottom: 8px;
`

// export default FormQuestionHeading
