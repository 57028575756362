import * as React from 'react'
import styled from 'styled-components'
import fax from '../../svg/fax.svg'
import phone from '../../svg/phone.svg'

// Footer
const FooterBackground = styled.div`
  height: 140px;
  background-color: ${(props) => props.theme.bodyText};

  @media (max-width: 770px) {
    height: 170px;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  padding: 0px 20px;

  width: 70%;
  margin: auto;
`

export const ContactBlock = styled.div`
  flex: 1;
  padding: 30px 50px;
  padding-right: 0px;

  @media (max-width: 770px) {
    padding-left: 40px;
  }
`

export const TextBlock = styled.div`
  flex: 2;
  padding: 30px 50px;
  padding-top: 40px;

  @media (max-width: 730px) {
    padding: 30px 40px;
  }
`

export const Location = styled.p`
  font-weight:  bold;
  font-size: 1.4em;
`

export const Svg = styled.img`
  margin-right: 5px;
  position: relative;
  top: 7px;
  height: 22px;

  @media (max-width: 770px) {
    display: none;
  }
`

export const AltText = styled.span`
  display: none;
  font-weight: bold;
  font-size: 1.1em;

  @media (max-width: 770px) {
    display: inline;
  }
`

export const ContactDetail = styled.a`
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
`

export const Footer = () => (
  <FooterBackground>
    <FooterContainer>
      <ContactBlock>
        <div>
          <Svg src={phone} alt={'Phone'}/>
          <AltText>T: </AltText>
          <ContactDetail href={'tel:1300 869 888'}>1300&nbsp;869&nbsp;888</ContactDetail>
        </div>
        <div>
          <Svg src={fax} alt={'Fax'}/>
          <AltText>F: </AltText>
          <ContactDetail>(03)&nbsp;9086&nbsp;4106</ContactDetail>
        </div>
      </ContactBlock>
      <TextBlock>
        <p>
          Seer Medical Pty. Ltd. uses the latest technology to improve healthcare for all people.
        </p>
      </TextBlock>
    </FooterContainer>
  </FooterBackground>
)
