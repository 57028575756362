import styled from 'styled-components'

// Header
export const Heading = styled.h1`
  font-weight: bold;
  font-size: 40px;
  color: ${(props) => props.theme.main};

  margin-top: 2px;
  padding: 16px;
`

// export default Header
