import React from 'react'
import '../App.css'
import {
  BodyContainer,
  FormWrapper,
  Heading,
  FormQuestionWrapper,
} from '../../components/generic'

export const Registration = () => {
  return (
    <BodyContainer>
      <FormWrapper>
        <Heading>
          Registration
        </Heading>
        <FormQuestionWrapper>
          Sorry, this form is no longer in use.
        </FormQuestionWrapper>
        <FormQuestionWrapper>
          If you are looking to complete your registration for at-home video-EEG-ECG monitoring with Seer Medical, please contact us at <a href={'mailto:info@seermedical.com?Subject=Registration%20form'}>info@seermedical.com</a>.
        </FormQuestionWrapper>
      </FormWrapper>
    </BodyContainer>
  )
}
