import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  FormWrapper,
  Heading,
  FormQuestionWrapper,
  Button,
  SingleInput,
  SelectInput,
  SectionHeading,
} from '../generic'

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 34px;
`

const titleOptionsList = [
  'Dr',
  'Prof.',
  'A/Prof.',
  'Mr',
  'Mrs',
  'Ms',
]

const titleOptions = titleOptionsList.map((v) => ({
  value: v,
  label: v,
}))

export const BookingUpdateClinician = ({
  handleClinicianEmailChange,
  clinEmail,
  handleClinicianTitleChange,
  clinTitle,
  handleClinicianFirstNameChange,
  clinFirstName,
  handleClinicianLastNameChange,
  clinLastName,
  returnToForm,
  updateAndSubmit,
  emailSubmitting
}) => {
  return (
    <FormWrapper>
      <Heading>
        Add Clinician Details
      </Heading>
      <FormQuestionWrapper>
        We have no record of a clinician with the email provided.
        <br/><br/>
        Please provide their details below, and they will be added to our list.
        <br/><br/>
        Ensure that the details are correct, since the name provided will be used to address the clinicians.
        <br/>
        <SectionHeading>
          Clinician Information
        </SectionHeading>

        <SingleInput
          inputType={'text'}
          title={'Email'}
          name={'email clin'}
          controlFunc={handleClinicianEmailChange}
          content={clinEmail}
          autoComplete={'off'}
          required={true}
        />

        <SelectInput
          title={'Title'}
          width={'40%'}
          selectProps={{
            tabIndex: 0,
            onChange: handleClinicianTitleChange,
            options: titleOptions,
            value: clinTitle,
          }}
        />

        <SingleInput
          inputType={'text'}
          title={'Given name'}
          name={'given name clin'}
          controlFunc={handleClinicianFirstNameChange}
          content={clinFirstName}
          subheading={'Ensure this is formatted and spelt correctly, as the clinician will be addressed by this name.'}
          autoComplete={'off'}
          placeholder={'e.g. Mark'}
          required={true}
        />

        <SingleInput
          inputType={'text'}
          title={'Family name'}
          name={'family name clin'}
          controlFunc={handleClinicianLastNameChange}
          content={clinLastName}
          subheading={'Ensure this is formatted and spelt correctly.'}
          autoComplete={'off'}
          placeholder={'e.g. Cook'}
          required={true}
        />
        <ButtonSection>
          <Button
            small
            onClick={returnToForm}
            disabled={emailSubmitting}
          >
            Return to form without updating
          </Button>
          <Button
            small
            onClick={updateAndSubmit}
            disabled={!clinFirstName || !clinLastName || !clinEmail || emailSubmitting}
          >
            Update details and submit form
          </Button>
        </ButtonSection>
      </FormQuestionWrapper>
    </FormWrapper>
  )
}

BookingUpdateClinician.propTypes = {
  handleClinicianEmailChange: PropTypes.func.isRequired,
  clinEmail: PropTypes.string.isRequired,
  handleClinicianTitleChange: PropTypes.func.isRequired,
  clinTitle: PropTypes.string,
  handleClinicianFirstNameChange: PropTypes.func.isRequired,
  clinFirstName: PropTypes.string.isRequired,
  handleClinicianLastNameChange: PropTypes.func.isRequired,
  clinLastName: PropTypes.string.isRequired,
  returnToForm: PropTypes.func.isRequired,
  updateAndSubmit: PropTypes.func.isRequired,
  emailSubmitting: PropTypes.bool.isRequired
}
