import React from 'react'
import PropTypes from 'prop-types'
import {
  FormWrapper,
  Heading,
  FormQuestionWrapper,
  ClickLink,
} from '../generic'

export const BookingPasswordFail = ({ handleClick }) => {
  return (
    <FormWrapper>
      <Heading>
        Incorrect password
      </Heading>
      <FormQuestionWrapper>
        An incorrect password was entered. Please <ClickLink onClick={handleClick}>try again</ClickLink>.
        <br/><br/>
        If the problem persists, please <ClickLink href='mailto:braden@seermedical.com'>send us an email</ClickLink>.
      </FormQuestionWrapper>
    </FormWrapper>
  )
}

BookingPasswordFail.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
