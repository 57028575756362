import React from 'react'
import PropTypes from 'prop-types'
import {
  FormWrapper,
  Heading,
  FormQuestionWrapper,
  ClickLink,
} from '../generic'

export const BookingFail = ({ handleClick }) => {
  return (
    <FormWrapper>
      <Heading>
        Booking details update failed
      </Heading>
      <FormQuestionWrapper>
        Unfortunately, your booking update form could not be processed.
        <br/><br/>
        If you have just added a clinician&apos;s email, try again in 10 minutes. There&apos;s some delay in getting the email online, so chances are if you try later, things will be ready to go.
        <br /><br />
        If you have not already, please <ClickLink onClick={handleClick}>try again</ClickLink>
        <br /><br />
        If the problem persists, please <ClickLink href='mailto:braden@seermedical.com'>send us an email</ClickLink> or call us on 1300&nbsp;869&nbsp;888.
      </FormQuestionWrapper>
    </FormWrapper>
  )
}

BookingFail.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
