import styled from 'styled-components'

// Single input styles
export const FormQuestionWrapper = styled.div`
  margin-top: 2px;
  padding: 12px 16px;
  display: ${(props) => props.hidden ? 'none' : 'inherit'}
`

// export default FormQuestionWrapper
