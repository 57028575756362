import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  FormWrapper,
  Heading,
  FormQuestionWrapper,
  ClickLink,
} from '../generic'

export const Ul = styled.ul`
  padding-left: 30px;
`

export const Li = styled.li`
  margin-top: 15px;
`

export const BookingThanks = ({ handleClick }) => {
  return (
    <FormWrapper>
      <Heading>
        Thank you
      </Heading>
      <FormQuestionWrapper>
        Your response has been received.
        <br /><br />
        You can now
        <Ul>
          <Li>
            <ClickLink onClick={handleClick}>update more booking details</ClickLink>,
          </Li>
          <Li>
            return to the <ClickLink href='https://www.seermedical.com'>homepage</ClickLink>.
          </Li>
        </Ul>
      </FormQuestionWrapper>
    </FormWrapper>
  )
}

BookingThanks.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
