import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import {
  SingleInput,
  SelectInput,
  Button,
  SectionHeading,
  FormQuestionWrapper,
  FormQuestionHeading,
  Optional,
  FormWrapper,
  Heading,
  CheckboxOrRadioGroupNoHeading,
} from '../generic'

const Form = styled.form`
  & #username {
    position: absolute;
    user-select: none;
    visibility: hidden;
  }
`

export const ButtonWrapper = styled.div`
  text-align: center;
  padding: 16px;
`

const startTime = moment().hour(7).minute(0)

const connectionTimeOptions = []
const endTime = startTime.clone().hour(20).minute(0)
while (!startTime.isSameOrAfter(endTime)) {
  connectionTimeOptions.push({
    value: startTime.format('HH:mm'),
    label: startTime.format('h:mma'),
  })
  startTime.add(5, 'minutes')
}

const isOutsideRange = () => false

export const BookingForm = ({
  debug,
  onFormSubmit,
  onPasswordChange,
  password,
  onSendToClinicianChange,
  sendToClinician,
  onClinicianEmailChange,
  clinEmail,
  onPatientFullNameChange,
  patFullName,
  onSendToPatientChange,
  sendToPatient,
  onPatientContactNameChange,
  patContactName,
  onPatientPhoneChange,
  patPhone,
  onReminderTimeChange,
  reminderTime,
  onDatesChange,
  startDate,
  endDate,
  focusedInput,
  onFocusChange,
  onConnectionTimeChange,
  connectionTime,
  onDisconnectionTbaChange,
  isDisconnectionTba,
  onDisconnectionTimeChange,
  disconnectionTime,
  submitting,
  isSubmitting
}) => {
  const isRequired = !debug

  return (
    <FormWrapper>
      <Heading>
        Booking Details
      </Heading>
      <Form onSubmit={onFormSubmit}>
        <input id='username' tabIndex={-1} value='Seer Staff' />
        {/* Password */}
        <SingleInput
          inputType='password'
          title='Password'
          name='password'
          controlFunc={onPasswordChange}
          content={password}
          autoComplete='on'
          required={isRequired}
        />
        {/* Clinician information */}
        <SectionHeading>
          Clinician Information
        </SectionHeading>
        <FormQuestionWrapper>
          <CheckboxOrRadioGroupNoHeading
            title=''
            setName='send clinician'
            type='checkbox'
            controlFunc={onSendToClinicianChange}
            options={['true']}
            labels={[`Send notification emails to clinician`]}
            selectedOptions={sendToClinician}
            required={false}
          />
        </FormQuestionWrapper>

        {sendToClinician.length > 0 && (
          <SingleInput
            inputType='text'
            title='Email (clinician)'
            name='clinEmail'
            controlFunc={onClinicianEmailChange}
            content={clinEmail}
            subheading='Only complete this field if a notification email is to be sent to the clinician.'
            autoComplete='on'
            required={false}
          />
        )}

        {/* Patient information */}
        <SectionHeading>
          Patient Information
        </SectionHeading>
        <SingleInput
          inputType='text'
          title='Full name'
          name='fullName'
          controlFunc={onPatientFullNameChange}
          content={patFullName}
          placeholder='e.g. John Smith'
          autoComplete='off'
          required={isRequired}
        />
        <FormQuestionWrapper>
          <CheckboxOrRadioGroupNoHeading
            title=''
            setName='send patient'
            type='checkbox'
            controlFunc={onSendToPatientChange}
            options={['true']}
            labels={[`Send reminder texts to patient`]}
            selectedOptions={sendToPatient}
            required={false}
          />
        </FormQuestionWrapper>
        {sendToPatient.length > 0 && (
          <>
            <SingleInput
              inputType='text'
              title='Contactable name'
              name='contactableName'
              controlFunc={onPatientContactNameChange}
              content={patContactName}
              placeholder='e.g. John'
              subheading='This is the name the recipient will be addressed by in their messages! Choose carefully.'
              autoComplete='off'
              required={isRequired}
            />
            <SingleInput
              inputType='tel'
              title='Phone (mobile)'
              name='patPhone'
              controlFunc={onPatientPhoneChange}
              content={patPhone}
              subheading='Only complete this field if reminder texts are to be sent to the patient. Please include the international code prefix, e.g. +61 400 000 000.'
              autoComplete='off'
              required={false}
              regex={/^\+61\s?(\d\s?){9}$/}
              validationMessage='Enter a mobile phone number with international code (e.g. +61 400 000 000)'
            />
          </>
        )}
        {/* Booking information */}
        <SectionHeading>
          Booking Information
        </SectionHeading>
        <SelectInput
          title='Reminder time'
          width='30%'
          required={isRequired}
          subheading='The time of day to message the patient during their study'
          selectProps={{
            tabIndex: 0,
            onChange: onReminderTimeChange,
            options: connectionTimeOptions,
            value: reminderTime,
          }}
        />
        <FormQuestionWrapper>
          <FormQuestionHeading>
            Connection and disconnection date
            {!isRequired && <Optional />}
          </FormQuestionHeading>
          <DateRangePicker
            startDateId='startid'
            endDateId='endid'
            startDate={startDate}
            endDate={endDate}
            showClearDates
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            displayFormat='DD/MM/YYYY'
            isOutsideRange={isOutsideRange}
          />
        </FormQuestionWrapper>
        <SelectInput
          title='Connection time'
          width='30%'
          required={isRequired}
          selectProps={{
            tabIndex: 0,
            onChange: onConnectionTimeChange,
            options: connectionTimeOptions,
            value: connectionTime,
          }}
        />
        <FormQuestionWrapper>
          <CheckboxOrRadioGroupNoHeading
            title=''
            setName='disconnection tba'
            type='checkbox'
            controlFunc={onDisconnectionTbaChange}
            options={['true']}
            labels={[`Is disconnection time not yet known/to be advised?`]}
            selectedOptions={isDisconnectionTba}
            required={false}
          />
        </FormQuestionWrapper>
        {isDisconnectionTba.length === 0 && (
          <SelectInput
            title='Disconnection time'
            width='30%'
            required={isRequired}
            selectProps={{
              tabIndex: 0,
              onChange: onDisconnectionTimeChange,
              options: connectionTimeOptions,
              value: disconnectionTime,
            }}
          />
        )}
        {/* Button */}
        <ButtonWrapper>
          <Button
            isSubmitting={submitting}
            disabled={submitting || (!debug && (!startDate || !endDate))}
          >
            {isSubmitting ? 'Please wait...' : 'Add booking information'}
          </Button>
        </ButtonWrapper>
      </Form>
    </FormWrapper>
  )
}

BookingForm.propTypes = {
  debug: PropTypes.bool,
  onFormSubmit: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onSendToClinicianChange: PropTypes.func.isRequired,
  sendToClinician: PropTypes.array.isRequired,
  onClinicianEmailChange: PropTypes.func.isRequired,
  clinEmail: PropTypes.string.isRequired,
  onPatientFullNameChange: PropTypes.func.isRequired,
  patFullName: PropTypes.string.isRequired,
  onSendToPatientChange: PropTypes.func.isRequired,
  sendToPatient: PropTypes.array.isRequired,
  onPatientContactNameChange: PropTypes.func.isRequired,
  patContactName: PropTypes.string.isRequired,
  onPatientPhoneChange: PropTypes.func.isRequired,
  patPhone: PropTypes.string.isRequired,
  onReminderTimeChange: PropTypes.func.isRequired,
  reminderTime: PropTypes.any.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.momentObj,
  endDate: PropTypes.momentObj,
  focusedInput: PropTypes.oneOf(['startDate', 'endDate']),
  onFocusChange: PropTypes.func.isRequired,
  onConnectionTimeChange: PropTypes.func.isRequired,
  connectionTime: PropTypes.any.isRequired,
  onDisconnectionTbaChange: PropTypes.func.isRequired,
  isDisconnectionTba: PropTypes.array.isRequired,
  onDisconnectionTimeChange: PropTypes.func.isRequired,
  disconnectionTime: PropTypes.any.isRequired,
  submitting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}
