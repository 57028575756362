import React from 'react'
import styled from 'styled-components'

const OptionalWrapper = styled.span`
  font-weight: 100;
  font-size: 0.8em;
  margin-left: 6px;
  color: grey;
`

export const Optional = (props) => (
  <OptionalWrapper {...props}>
    {'(Optional)'}
  </OptionalWrapper>
)
