import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import styled from 'styled-components'
import {
  FormQuestionWrapper,
  FormQuestionHeading,
  FormQuestionSubheading,
  Optional,
} from './'

const SelectWrapper = styled.div`
  width: ${(props) => props.width || '80%'};
`

const SelectBox = (props) => (
  <SelectWrapper {...props}>
    <Select {...props}/>
  </SelectWrapper>
)

export class SelectInput extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    required: PropTypes.bool,
    subheading: PropTypes.string,
    width: PropTypes.string,
    selectProps: PropTypes.object.isRequired,
    content: PropTypes.string,
    regex: PropTypes.instanceOf(RegExp),
  }

  constructor(props) {
    super(props)
    this.state = {
      isValid: true,
    }
  }

  isValid() {
    const regex = this.props.regex || /./
    return !this.props.content || this.props.content.search(regex) !== -1
  }

  doValidation() {
    const isValid = this.isValid()
    this.setState({ isValid })
  }

  render() {
    return (
      <FormQuestionWrapper>
        <FormQuestionHeading>
          {this.props.title}
          {!this.props.required && <Optional/>}
        </FormQuestionHeading>
        {this.props.subheading && <FormQuestionSubheading>
          {this.props.subheading}
        </FormQuestionSubheading>}
        <SelectBox width={this.props.width} {...this.props.selectProps}/>
      </FormQuestionWrapper>
    )
  }
}
