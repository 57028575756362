import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Registration } from './registration'
import { Booking } from './booking'
import { Messaging } from './messaging'
import {
  Header,
  HeaderLeft,
  Container,
  // Footer,
} from '../components/generic'
import {
  colours,
} from '../configs/styles'

const External = () => (<Redirect to="/referral" />)

const HomeLink = styled.div`
  color: white;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
`

const BannerLogo = styled.img`
  height: 67px;
`

export const RoutedApp = () => {
  return (
    <ThemeProvider theme={colours}>
      <div>
        <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
        <Router>
          <Switch>
            <Route
              path={`/referral`}
              component={() => {
                window.location.href = 'https://app.seermedical.com/referral'
                return null
              }}
            />
            <Route
              exact
              path={`/(registration|messaging|booking)`}
              render={(componentProps) => (
                <Container>
                  {/* Header */}
                  <Header
                    style={componentProps.location.pathname.match('booking') || componentProps.location.pathname.match('messaging')
                      ? { background: 'linear-gradient(0.25turn, #f3f3f3, #f2f7f9, #fff7f8)' }
                      : {}
                    }
                  >
                    <HeaderLeft>
                      <HomeLink onClick={() => window.location = 'https://www.seermedical.com'}>
                        <BannerLogo src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Seer Medical" />
                      </HomeLink>
                    </HeaderLeft>
                  </Header>
                  {/* Form contents */}
                  <Route path="/registration" component={Registration}/>
                  <Route path="/booking" component={Booking}/>
                  <Route path="/messaging" component={Messaging}/>
                </Container>
              )}
            />
            <External/>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  )
}
