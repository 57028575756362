import styled from 'styled-components'

export const ButtonWrapper = styled.div`
text-align: center;
padding: 16px;
`

// Single input styles
export const Button = styled.button`
  cursor: pointer;
  font-weight: inherit;
  font-family: inherit;

  letter-spacing: 0.04em;
  text-align: center;

  width: 150px;
  height: 60px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;

  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => props.theme.offsetBackground};
  color: ${(props) => props.theme.bodyText};
  background-color: transparent;
  font-size: ${props => {
    return (props.large)
      ? '24px'
      : props.isSubmitting
        ? '16px'
        : props.small
          ? '16px'
          : '20px'
  }};

  transition: 0.1s background-color linear, 0.1s color linear, 0.1s border-color linear;

  &:hover {
    text-decoration: none;
    background-color: ${(props) => props.theme.offsetBackground};
    color: ${(props) => props.theme.mainBackground};
  }

  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
    color: white;
    border-color: #999;
    cursor: not-allowed;
  }

  ${(props) => {
    return (props.isSubmitting) &&
      `text-decoration: none;
        background-color: #1c3d78;
        color: white;
        cursor: wait;
        border-color: #1c3d78;
      `
  }}

  /* &:focus {
    outline: none;
  } */
`
