import React, { useState } from 'react'
import styled from 'styled-components'
import '../App.css'
import {
  BodyContainer,
} from '../../components/generic'
import {
  Button,
  FormWrapper,
  Heading,
} from '../../components/generic'
import { unstable_batchedUpdates } from 'react-dom'

const debug = false

const domain = debug
  ? 'http://localhost:3065'
  : 'https://cms.seermedical.com'

const Wrapper = styled(FormWrapper)`
  & input, textarea {
    width: 400px;
    height: 40px;
    font-size: 18px;
    padding-left: 8px;
  }

  & #username {
    position: absolute;
    user-select: none;
    visibility: hidden;
  }

  & label {
    display: block;
    margin-top: 16px;
  }

  & textarea {
    height: 120px;
    padding: 8px;
  }

  & h3 {
    margin-top: 16px;
  }
  & h3:first-child {
    margin-top: 0;
  }

  & h2 {
    font-size: 20px;
  }

  & ${Button} {
    width: 400px;
    margin: 0px;
    margin-top: 16px;
  }

  & ${Heading} {
    padding: 0px;
  }

  padding: 32px;
`

const parsePhoneNumber = (number) => {
  let formattedNumber = number
  formattedNumber = formattedNumber.replace(/\s/gi, '').replace(/[^(0-9|^+)]/gi, '')
  if (formattedNumber[0] === '0') {
    formattedNumber = '+61' + formattedNumber.slice(1)
  }
  return formattedNumber
}

const SENDING = 'sending'
const SUCCESS = 'success'

export const Messaging = () => {
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [number, setNumber] = useState('')
  const [status, setStatus] = useState('')
  const [error, setError] = useState('')

  const resetState = () => {
    unstable_batchedUpdates(() => {
      setMessage('')
      setNumber('')
      setStatus('')
      setError('')
    })
  }

  const handleSubmit = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (status !== '' || error) {
      return
    }
    const receivingNumber = parsePhoneNumber(number)
    if (!password || !message || !receivingNumber) {
      setError('One or more fields are missing')
      return
    }
    setStatus(SENDING)
    const response = await fetch(`${domain}/seer-messaging/send-sms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password,
        receivingNumber,
        messageBody: message,
      })
    })
    switch (response.status) {
    case 200:
      setStatus('success')
      break
    case 403:
      setStatus('')
      setError('Incorrect password')
      break
    case 500:
      setStatus('')
      setError((
        <span>
          Sorry, something went wrong.
          Please check that the phone number is valid,
          and if the error persists please contact <a href="mailto:braden@seermedical.com?subject=Messaging%20form%20broken">braden [at] seermedical [dot] com</a>.
        </span>
      ))
      break
    default:
      setStatus('')
      setError(response.statusText)
      break
    }
  }

  return (
    <BodyContainer>
      <Wrapper>
        <Heading>
          Send a message
        </Heading>
        <form onSubmit={handleSubmit}>
          {/* Password */}
          <input tabIndex={-1} id={'username'} value={'Seer Staff'} />
          <label>
            <h3>Password</h3>
            <input
              value={password}
              onChange={(e) => {
                setError('')
                setPassword(e.target.value)}
              }
              type={'password'}
            />
          </label>
          <label>
            <h3>Phone number</h3>
            <input
              value={number}
              onChange={(e) => {
                setError('')
                setNumber(e.target.value)
              }}
              onBlur={() => setNumber(parsePhoneNumber(number))}
              type={'tel'}
            />
          </label>
          <label>
            <h3>Message</h3>
            <textarea
              value={message}
              onChange={(e) => {
                setError('')
                setMessage(e.target.value)
              }}
            />
          </label>

          {/* Button */}
          {error && <h2>
            {error}
          </h2>}
          {status === SENDING && (
            <h2>
              Sending message...
            </h2>
          )}
          {status === SUCCESS && (
            <>
              <h2>
                Message successfully sent!
              </h2>
              <Button type={'button'} onClick={resetState}>
                {'Send another message? (resets form)'}
              </Button>
            </>
          )}
          {status !== SUCCESS && (
            <Button disabled={error || status === SENDING}>
              {status === SENDING ? 'Sending...' : 'Send message'}
            </Button>
          )}
        </form>
      </Wrapper>
    </BodyContainer>
  )
}
