import React, { Component } from 'react'
import '../App.css'
import {
  BodyContainer,
  Footer,
} from '../../components/generic'
import {
  BookingForm,
  BookingFail,
  BookingThanks,
  BookingPasswordFail,
  BookingUpdateClinician,
  BookingEmailSuccessPostFail,
} from '../../components/booking'

// disable body scroll
document.body.style.overflow = 'hidden'

// Forms
const MAIN = 'MAIN'
const THANKS = 'THANKS'
const POST_FAIL = 'POST_FAIL'
const PASSWORD_FAIL = 'Incorrect password'
const UPDATE_CLINICIAN = 'Update clinician'
const GOOD_EMAIL_BAD_POST = 'Email added, post not working still'

// Error messages
const CLINICIAN_EMAIL_FAIL = 'Clinician email not found'

const initialState = {
  password: '',
  patFullName: '',
  patContactName: '',
  patPhone: '',
  clinEmail: '',
  clinFirstName: '',
  clinLastName: '',
  clinTitle: '',
  reminderTime: {
    value: '09:00',
    label: '9:00am',
  },
  startDate: null,
  endDate: null,
  focusedInput: null,
  connectionTime: '',
  disconnectionTime: '',
  visibleForm: MAIN,
  submitting: false,
  emailAddingSuccess: false,
  emailSubmitting: false,
  replyPhone: {
    value: '1300 869 888',
    label: '1300 869 888',
  },
  sendToPatient: ['true'],
  sendToClinician: ['true'],
  isDisconnectionTba: [],
}

const wait = (ms) => new Promise((r) => setTimeout(r, ms))

const scrollToTop = () => {
  const body = document.getElementById('bodyContainer')
  return Promise.resolve()
    .then(() => body.scrollTo(0, 0))
    .catch(() => body.scrollTop = 0)
    .catch(() => null)
}

const debug = false

const domain = debug
  ? 'http://localhost:3065'
  : 'https://cms.seermedical.com'

// App --------------------------
export class Booking extends Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  //// handle submitting the form
  handleFormSubmit(e) {
    // prevent default submit
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    // return if already submitted
    if (this.state.submitting) {
      return
    }

    return this.setState({ submitting: true }, () => {
      // If offline, show error message then return
      if (!navigator.onLine) {
        alert('No network connection detected. Please connect to the internet before submitting this form.')
        return this.setState({ submitting: false })
      }

      const body = {
        fullName: this.state.patFullName.trim(),
        contactableName: this.state.patContactName.trim(),
        emailClin: this.state.sendToClinician.length > 0 ? this.state.clinEmail.trim() : null,
        phoneNumber: this.state.sendToPatient.length > 0 ? this.state.patPhone.replace(/\s/g, '') : null, // removing whitespace
        connectionDate: this.state.startDate && this.state.startDate.format('YYYY-MM-DD'),
        disconnectionDate: this.state.endDate && this.state.endDate.format('YYYY-MM-DD'),
        connectionTime: this.state.connectionTime ? this.state.connectionTime.value : null,
        disconnectionTime: this.state.isDisconnectionTba.length > 0
          ? 'TBA'
          : this.state.disconnectionTime
            ? this.state.disconnectionTime.value
            : null,
        reminderTime: this.state.reminderTime ? this.state.reminderTime.value : null,
        password: this.state.password,
        replyPhone: this.state.replyPhone ? this.state.replyPhone.value : null,
      }
      return Promise.resolve()
        .then(() => (
          fetch(`${domain}/seer-messaging/new-patient`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
        ))
        .then(response => response.text())
        .then((text) => {
          switch (text) {
          case 'Success':
            return
          case CLINICIAN_EMAIL_FAIL:
            throw new Error(CLINICIAN_EMAIL_FAIL)
          case 'Incorrect password':
            throw new Error('Incorrect password')
          case 'Error':
          default:
            throw new Error('Posting failed.')
          }
        })
        .then(() => this.onSuccessfulSubmit())
        .catch((e) => this.onFailSubmit(e))
    })
  }

  onSuccessfulSubmit() {
    return wait(200)
      .then(() => this.setState({
        submitting: false,
        visibleForm: THANKS,
      }))
      .then(() => scrollToTop())
  }

  onFailSubmit(e) {
    return wait(200)
      .then(() => {
        const visibleForm = (e.message === 'Incorrect password')
          ? PASSWORD_FAIL
          : e.message === CLINICIAN_EMAIL_FAIL
            ? UPDATE_CLINICIAN
            : this.state.emailAddingSuccess
              ? GOOD_EMAIL_BAD_POST
              : POST_FAIL

        return this.setState({
          emailSubmitting: false,
          submitting: false,
          visibleForm,
        })
      })
      .then(() => scrollToTop())
  }

  returnToForm() {
    return wait(200)
      .then(() => this.setState({ visibleForm: MAIN }))
      .then(() => scrollToTop())
  }

  updateAndSubmit() {
    return this.setState({ emailSubmitting: true }, () => {
      return wait(200)
        .then(() => {
          const body = {
            clinEmail: this.state.clinEmail.trim(),
            clinFirstName: this.state.clinFirstName.trim(),
            clinLastName: this.state.clinLastName.trim(),
            clinTitle: this.state.clinTitle && this.state.clinTitle.value,
          }
          return Promise.resolve()
            .then(() => (
              fetch(`${domain}/seer-messaging/add-clinician`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
              })
            ))
            .then(response => response.text())
            .then((text) => {
              switch (text) {
              case 'Success':
                return
              case 'Error':
              default:
                throw new Error('Posting failed.')
              }
            })
            .then(() => this.setState({ emailAddingSuccess: true }))
            .then(() => this.handleFormSubmit())
            .catch((e) => this.onFailSubmit(e))
        })
    })
  }

  handleCheckboxChange(e, stateToChange) {
    const newSelection = e.target.value
    const newSelectionArray = (this.state[stateToChange].indexOf(newSelection) > -1)
      ? this.state[stateToChange].filter((s) => (s !== newSelection))
      : [...this.state[stateToChange], newSelection]
    this.setState({ [stateToChange]: newSelectionArray })
  }

  resetState() {
    // eslint-disable-next-line no-unused-vars
    const { password, ...rest } = initialState
    this.setState({ ...rest })
  }

  render() {
    const { visibleForm } = this.state

    return (
      <BodyContainer id={'bodyContainer'}>
        {visibleForm === THANKS &&
          <BookingThanks handleClick={() => this.resetState()}/>
        }

        {visibleForm === POST_FAIL &&
          <BookingFail handleClick={() => this.setState({ visibleForm: MAIN })}/>
        }

        {visibleForm === UPDATE_CLINICIAN &&
          <BookingUpdateClinician
            returnToForm={() => this.returnToForm()}
            updateAndSubmit={() => this.updateAndSubmit()}
            handleClinicianEmailChange={(e) => this.setState({ clinEmail: e.target.value })}
            handleClinicianTitleChange={(val) => this.setState({ clinTitle: val })}
            handleClinicianFirstNameChange={(e) => this.setState({ clinFirstName: e.target.value })}
            handleClinicianLastNameChange={(e) => this.setState({ clinLastName: e.target.value })}
            {...this.state}
          />
        }

        {visibleForm === GOOD_EMAIL_BAD_POST &&
          <BookingEmailSuccessPostFail handleClick={() => this.setState({ visibleForm: MAIN, emailAddingSuccess: false })}/>
        }

        {visibleForm === PASSWORD_FAIL &&
          <BookingPasswordFail handleClick={() => this.setState({ visibleForm: MAIN })}/>
        }

        {visibleForm === MAIN &&
          <BookingForm
            onFormSubmit={(e) => this.handleFormSubmit(e)}
            onPasswordChange={(e) => this.setState({ password: e.target.value })}
            onPatientFullNameChange={(e) => this.setState({ patFullName: e.target.value })}
            onPatientContactNameChange={(e) => this.setState({ patContactName: e.target.value })}
            onClinicianEmailChange={(e) => this.setState({ clinEmail: e.target.value })}
            onPatientPhoneChange={(e) => this.setState({ patPhone: e.target.value })}
            onReminderTimeChange={(val) => this.setState({ reminderTime: val })}
            onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
            onConnectionTimeChange={(val) => this.setState({ connectionTime: val })}
            onDisconnectionTimeChange={(val) => this.setState({ disconnectionTime: val })}
            onReplyPhoneChange={(val) => this.setState({ replyPhone: val })}
            onSendToPatientChange={(e) => this.handleCheckboxChange(e, 'sendToPatient')}
            onSendToClinicianChange={(e) => this.handleCheckboxChange(e, 'sendToClinician')}
            onDisconnectionTbaChange={(e) => this.handleCheckboxChange(e, 'isDisconnectionTba')}
            {...this.state}
            debug={debug}
          />
        }
        <Footer/>
      </BodyContainer>
    )
  }
}

// ----------------------------------
// export default Booking
